import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter {
  constructor(sources) {
    super()

    // Options
    this.sources = sources
    this.setLoaders()
    this.startLoading()



    // Setup
    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0
  }

  setLoaders() {
    this.loaders = {}
    this.loaders.loadingManager = new THREE.LoadingManager();

    this.loaders.loadingManager.onProgress = function(url, caricato, total) {
      this.progressBar = document.getElementById('progress-bar');
        this.progressBar.value = (caricato / total) * 100;
    }

    this.loaders.loadingManager.onLoad = function() {
      this.progressBarContainer = document.getElementById('progress-container');
        this.progressBarContainer.classList.add("opacity0");
    }

    

    this.loaders.gltfLoader = new GLTFLoader(this.loaders.loadingManager)
    this.loaders.textureLoader = new THREE.TextureLoader()
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
    this.loaders.dracoLoader = new DRACOLoader()
    this.loaders.dracoLoader.setDecoderConfig({ type: 'js' });
    this.loaders.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)

    
  }

  startLoading() {
    // Load each source
    for (const source of this.sources) {
      if (source.type === 'gltfModel') {
        this.loaders.gltfLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file)
        })
      } else if (source.type === 'texture') {
        this.loaders.textureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file)
        })
      } else if (source.type === 'cubeTexture') {
        this.loaders.cubeTextureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file)
        })
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file

    this.loaded++

    if (this.loaded === this.toLoad) {
      this.trigger('ready')
    }
  }
}
