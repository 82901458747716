import * as THREE from 'three'

import Experience from '../Experience.js'

export default class Moon {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time

    // Setup
    this.setGeometry()
    this.setTextures()
    this.setMaterial()
    this.setMesh()
  }

  setGeometry() {
    this.geometry = new THREE.SphereBufferGeometry(2, 32, 32)
  }

  setTextures() {
    this.textures = {}

    this.textures.color = this.resources.items.textureMoon

    this.textures.normal = this.resources.items.normalMapMoon
  }

  setMaterial() {
    this.material = new THREE.MeshStandardMaterial({
      map: this.textures.color,
      color: 0xffffff,
      // vertexColors: true,
      // normalMap: this.textures.normal,
      metalness: 1,
      roughness :1,
    })
  }

  setMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.material)
    // this.mesh.rotation.x = -Math.PI * 0.5
    // this.mesh.receiveShadow = true
    // this.mesh.castShadow = true
    this.scene.add(this.mesh)
  }

  animate(){
    this.mesh.rotateY(0.004)
  }
}
