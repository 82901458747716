import * as THREE from 'three'

import Sizes from "./Utils/Sizes.js"
import Time from "./Utils/Time.js"
import Camera from "./Camera.js"
import Renderer from "./Renderer.js"
// import Projects from "./Utils/Projects"
import Timeline from "./Utils/Timeline.js"
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import sources from './sources.js'

let instance = null

export default class Experience{
    constructor(canvas){

        // Singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Canvas
        this.canvas = canvas

        // Setup
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera(this)
        this.timeline = new Timeline()
        // this.project = new Projects(".project")
        this.renderer = new Renderer()
        this.world = new World()  

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

            // Time tick event
        this.time.on('tick', () => {
            this.update()
        })
    }

    // On Resize
    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    // On Update
    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }


}