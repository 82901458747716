// Importa GSAP e ScrollTrigger
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Registra ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export default class ScrollAnimation {
  constructor() {
    // Array per mantenere tutte le timeline
    this.animations = [];

    // Metodo per creare animazioni
    this.init();
  }

  init() {

    // SECTION 2 *********************************************************************
    this.createAnimation({
      trigger: '#section02',
      start: 'top 80%',
      end: 'top center',
      scrub: true,
      animations: [
        { target: 'body', properties: { backgroundColor: '#fff', color: '#000' }, duration: 0.1 }
      ]
    });
    this.createAnimation({
      trigger: '#section02',
      start: 'top bottom',
      end: 'top 60%',
      scrub: true,
      // markers: true,
      animations: [
        { target: '.containerTitle01, #three-section', properties: { opacity: '0' }, duration: 1 },
        { target: '.mainBtn', properties: { backgroundColor: '#000', color: '#fff' }, duration: 1 },
      ]
    });

  //   // SECTION 3 *********************************************************************
  //   this.createAnimation({
  //     trigger: '#section03',
  //     start: 'top 80%',
  //     end: 'top 50%',
  //     scrub: true,
  //     markers: true,
  //     animations: [
  //       { target: 'body', properties: { backgroundColor: '#000', color: '#fff' }, duration: 1 },
  //       { target: '.containerLateralWord', properties: { opacity: '0' }, duration: 1 },
  //       { target: '.mainBtn', properties: { backgroundColor: '#fff', color: '#000' }, duration: 1 },
  //       { target: '.projectContainer', properties: { opacity: '1' }, duration: 1 }
  //     ]
  //   });

  //   this.createAnimation({
  //     trigger: '#section04',
  //     start: 'center 80%',
  //     end: 'center 60%',
  //     scrub: true,
  //     // markers: true,
  //     animations: [
  //       { target: '.mainBtn', properties: { backgroundColor: '#FFDE2E', color: '#000' }, duration: 1 },
  //     ]
  //   });

  //   // Seleziona tutti gli elementi .lateralWord
  //   this.lateralWords = document.querySelectorAll('.lateralWord');

  //   this.lateralWords.forEach((word, index) => {
  //     this.createLateralWordAnimation(word, index);
  //   });

  //   this.finalText = document.querySelectorAll('.finalText');
  //   this.finalText.forEach((word, index) => {
  //     this.createFinalTextAnimation(word, index);
  //   });

  //   this.wannabe = document.querySelectorAll('.wannabe');
  //   this.wannabe.forEach((word, index) => {
  //     this.createFinalTextAnimationEnd(word, index);
  //   });
  // }

  // // Metodo per creare un'animazione per le lateralWord
  // createLateralWordAnimation(target, index) {
  //   const start = `top bottom`; // Inizio dell'animazione
  //   const end = `top 70%`; // Fine dell'animazione

  //   gsap.fromTo(target,
  //     { x: -100, y: 30, opacity: 0, rotation: 2 }, // Partenza
  //     {
  //       x: 0,
  //       y: 0,
  //       opacity: 1,
  //       rotation: 0,
  //       duration: 1,
  //       ease: 'power1.out',
  //       scrollTrigger: {
  //         trigger: target,
  //         start: start,
  //         end: end,
  //         scrub: true,
  //         lazy: true, // Aggiunto lazy
  //         // markers: true
  //       }
  //     }
  //   );
  // }

  // createFinalTextAnimation(target, index) {
  //   const start = `top 80%`; // Inizio dell'animazione
  //   const end = `top 40%`; // Fine dell'animazione

  //   gsap.fromTo(target,
  //     { y: 200, opacity: 0, rotation: 2 }, // Partenza
  //     {
  //       y: 0,
  //       opacity: 1,
  //       rotation: 0,
  //       duration: 1,
  //       ease: 'power1.out',
  //       scrollTrigger: {
  //         trigger: target,
  //         start: start,
  //         end: end,
  //         scrub: true,
  //         lazy: true, // Aggiunto lazy
  //         // markers: true
  //       }
  //     }
  //   );
  // }

  // createFinalTextAnimationEnd(target, index) {
  //   const start = `top bottom`; // Inizio dell'animazione
  //   const end = `top 75%`; // Fine dell'animazione

  //   gsap.fromTo(target,
  //     { y: 100, opacity: 0, rotation: 2 }, // Partenza
  //     {
  //       y: 0,
  //       opacity: 1,
  //       rotation: 0,
  //       duration: 1,
  //       ease: 'power1.out',
  //       scrollTrigger: {
  //         trigger: target,
  //         start: start,
  //         end: end,
  //         scrub: true,
  //         lazy: true, // Aggiunto lazy
  //         // markers: true
  //       }
  //     }
  //   );
  // }

  // createWannabeAnimation(target) {
  //   const start = `top 90%`; // Inizio dell'animazione
  //   const end = `top 50%`; // Fine dell'animazione

  //   gsap.fromTo(target,
  //     { x: -500, opacity: 0 }, // Partenza
  //     {
  //       x: 0,
  //       opacity: 1,
  //       rotation: 0,
  //       duration: 1,
  //       ease: 'power1.out',
  //       scrollTrigger: {
  //         trigger: "#wannabeFantastic",
  //         start: start,
  //         end: end,
  //         scrub: true,
  //         markers: true,
  //         lazy: true, // Aggiunto lazy
  //       }
  //     }
  //   );
  // }

  // createFantasticAnimation(target) {
  //   const start = `top 90%`; // Inizio dell'animazione
  //   const end = `top 50%`; // Fine dell'animazione

  //   gsap.fromTo(target,
  //     { x: 500, opacity: 0 }, // Partenza
  //     {
  //       x: 0,
  //       opacity: 1,
  //       rotation: 0,
  //       duration: 1,
  //       ease: 'power1.out',
  //       scrollTrigger: {
  //         trigger: "#wannabeFantastic",
  //         start: start,
  //         end: end,
  //         scrub: true,
  //         markers: true,
  //         lazy: true, // Aggiunto lazy
  //       }
  //     }
  //   );
  }

  // Metodo per creare una singola animazione
  createAnimation({ trigger, start, end, scrub, markers, animations }) {
    // Crea una nuova timeline per le animazioni
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: start,
        end: end,
        scrub: scrub,
        markers: markers,
        lazy: true, // Aggiunto lazy
      }
    });

    // Aggiungi ogni animazione alla timeline con lo stesso punto di partenza "0" (simultaneo)
    animations.forEach(anim => {
      tl.to(anim.target, { ...anim.properties, duration: anim.duration, ease: anim.ease || 'power2.inOut' }, 0); // "0" forza l'esecuzione simultanea
    });

    // Aggiungi la timeline all'array delle animazioni
    this.animations.push(tl);
  }
}
