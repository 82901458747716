import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Satellities {
  constructor(sourceName, color, x, y, z, rx1, ry1, rz1, rx2, ry2, rz2) {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.sourceName = sourceName
    this.containerModel = new THREE.Object3D()
    this.containerSatellite = new THREE.Object3D()
    this.pointLightSatellite = new THREE.PointLight(color, 2.5, 10);
    this.pointLightSatellite2 = new THREE.PointLight(0xF8673D, 2.5, 15);
    this.pointLightSatellite3 = new THREE.PointLight(0x58CEFF, 2.5, 15);
    
    // this.pointLightSatellite.castShadow = true
    this.x = x
    this.y = y
    this.z = z
    this.rx1 = rx1
    this.ry1 = ry1
    this.rz1 = rz1
    this.rx2 = rx2
    this.ry2 = ry2
    this.rz2 = rz2

    // Setup (usare la notazione con parentesi quadre)
    this.resource = this.resources.items[sourceName]
    this.time = this.experience.time

    this.setModel()
    this.animate()
  }

  setModel() {
    this.model = this.resource.scene
    this.containerModel.add(this.model)
    this.containerModel.add(this.pointLightSatellite);
    if(this.sourceName == 'figma'){
      this.pointLightSatellite2.position.x = .2
      this.pointLightSatellite3.position.x = -.2
      this.containerModel.add(this.pointLightSatellite2);
      this.containerModel.add(this.pointLightSatellite3);
    }
    this.containerSatellite.add(this.containerModel);

    this.containerModel.position.set(this.x, this.y, this.z)
    this.scene.add(this.containerSatellite)
  }

  animate(){
    this.containerModel.rotateX(this.rx1)
    this.containerModel.rotateY(this.ry1)
    this.containerModel.rotateZ(this.rz1)
    this.containerSatellite.rotateX(this.rx2)
    this.containerSatellite.rotateY(this.ry2)
    this.containerSatellite.rotateZ(this.rz2)
  }
}
