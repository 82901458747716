import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter{
    constructor(){
        super()

        //user agent
        this.userAgent = window.navigator.userAgent;
        this.iOS =this.userAgent.match(/iPad/i) ||this.userAgent.match(/iPhone/i);
        this.webkit =this.userAgent.match(/WebKit/i);
        this.iOSSafari = this.iOS && this.webkit && !this.userAgent.match(/CriOS/i);

        //width height
        this.width = window.innerWidth
        if(this.width < 1200){
            this.height = 600
        }
        else{
            this.height = 600
        }
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // this.antiLandscape = document.getElementById('antiLandscape')
        // //device orientation
        // this.landscape = false
        // if(window.matchMedia("(orientation: landscape)").matches){
        //     this.landscape = true
        //     this.antiLandscape.style.display = "flex"
        // }

        // Resize event
        window.addEventListener('resize', () => {

            this.width = window.innerWidth
            if(this.width < 1200){
                this.height = 600

            }
            else{
                this.height = 600
            }

            
            // this.height = 900
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
    
            this.trigger('resize')
        })

        // window.addEventListener("orientationchange", (event) => {
        //     console.log('change')

        //     // this.trigger('resize')

        //     if(window.matchMedia("(orientation: portrait)").matches){
        //         this.antiLandscape.style.display = "flex"
        //     }
        //     else{
        //         this.antiLandscape.style.display = "none"
        //         this.width = window.innerWidth
        //         this.height = window.innerHeight
        //     }

        //     this.trigger('orientationchange')

        //     this.width = window.innerWidth
        //     this.height = window.innerHeight
            
        // });


    }
}