import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
    constructor() {
      // Setup
      this.experience = new Experience()
      this.scene = this.experience.scene
    //   this.resources = this.experience.resources
  
      // Debug
    //   this.debug = this.experience.debug
  
    //   if (this.debug.active) {
    //     this.debugFolder = this.debug.ui.addFolder('environment')
    //   }
  
    this.setAmbientLight()
    }

    setAmbientLight() {
        this.ambientLightWhite = new THREE.PointLight(0xffffff, 6, 60)
        this.ambientLightWhite.position.set(0, 0, 20)
        // this.scene.add(this.ambientLightWhite)
    }

    setAxes(){
      this.axesHelper = new THREE.AxesHelper(10); // AxesHelper(lunghezza3segmenti)
      this.scene.add(this.axesHelper);
    }
}