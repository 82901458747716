import * as THREE from 'three'

import Experience from '../Experience.js'
import Environment from './Environment.js'
import Moon from './Moon.js'
import Diamond from './Diamond.js'
import Satellities from './Satellitis.js'
import Rocket from './Rocket.js'
// import Smoke from './Smoke.js'

export default class World {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene

    this.resources = this.experience.resources

    // Wait for resources
    this.resources.on('ready', () => {
      // Setup
      this.environment = new Environment()
      this.moon = new Moon()
      // this.diamond = new Diamond()
      // this.rocket = new Rocket()
      // this.smoke1 = new Smoke()
      this.ps = new Satellities('photoshop',    0x316AFF,   -1,     -2.5,   0,    0.01,     0.01,     0.01,     0.007,     0,        0         )
      this.ai = new Satellities('illustrator',  0xff9a00,   -2.5,   1,      0,    0.02,     0.02,     0.02,     0,        -0.007,    0         )
      this.ae = new Satellities('afterEffects', 0x9941FF,   2.3,    2.3,    0,    0.01,     0.01,     0.01,     0.003,    -0.003,   0         )
      this.blender = new Satellities('blender', 0xFFA400,   2.3,    -2.3,   0,    0,        0,        0.02,     0.003,    0.003,    0         )
      this.figma = new Satellities('figma',     0x3CDB8F,   0,      2.5,    0,    0.01,     0.01,     0.01,     0,        0,        0.007      )
      this.vscode = new Satellities('vscode',   0x207ACB,   0,      -1,     2.5,  0.01,     0.01,     0.01,     0,        0.007,     0         )
      this.js = new Satellities('javascript',   0xF7DF1E,   1,      -2.5,   0,    0,        0.01,     0.01,     -0.007,    0,        0         )



    })

    // Set up

  }

  update() {
    if (this.moon) this.moon.animate()
    if (this.rocket) this.rocket.animate()
    if (this.ps) this.ps.animate()
    if (this.ai) this.ai.animate()
    if (this.ae) this.ae.animate()
    if (this.blender) this.blender.animate()
    if (this.figma) this.figma.animate()
    if (this.vscode) this.vscode.animate()
    if (this.js) this.js.animate()
    if (this.diamond) this.diamond.animate()

  }
}
