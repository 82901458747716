export default [
    {
      name: 'smoke1',
      type: 'texture',
      path: 'textures/smoke1.png',
    },
    {
      name: 'textureMoon',
      type: 'texture',
      path: 'textures/moon.jpg',
    },
    {
      name: 'normalMapMoon',
      type: 'texture',
      path: 'textures/normalMapMoon.jpg',
    },
    {
      name: 'photoshop',
      type: 'gltfModel',
      path: 'models/ps.glb',
    },
    {
      name: 'illustrator',
      type: 'gltfModel',
      path: 'models/ai.glb',
    },
    {
      name: 'afterEffects',
      type: 'gltfModel',
      path: 'models/ae.glb',
    },
    {
      name: 'blender',
      type: 'gltfModel',
      path: 'models/blender.glb',
    },
    {
      name: 'figma',
      type: 'gltfModel',
      path: 'models/figma.glb',
    },
    {
      name: 'vscode',
      type: 'gltfModel',
      path: 'models/vscode.glb',
    },
    {
      name: 'javascript',
      type: 'gltfModel',
      path: 'models/javascript.glb',
    },
    {
      name: 'rocket',
      type: 'gltfModel',
      path: 'models/rocket.glb',
    },
    {
      name: 'macbook',
      type: 'gltfModel',
      path: 'models/macbook.glb',
    },
  ]
  